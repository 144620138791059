import "../sass/main.scss";

//import "slick-carousel/slick/slick";
import "./jquery.vnm.percentWithinViewport.min";

import "./script-modal";
import "./_sprite";
import "./script-components";
import "./masonry";
jQuery(document).ajaxComplete(function () {
    initialiseMain();
});
jQuery(window).resize(function () {
    sliderSlickFullWidth();
    stickToSides();
    stickyTop();
});

jQuery(document).ready(function () {
    initialiseMain();
    initialiseFooter();
    stickToSides();
    initialiseParallax();
    initialiseDelayTransitions();
    //delayTransitions();
    stickyTop();
    //initBackToTop();
    initBookForm();
});

jQuery(window).load(function () {
    sliderSlickFullWidth();
});


jQuery(window).scroll(function() {
    //runParallax();
    //delayTransitions();
    stickyTop();
});

jQuery('.button-arrow').each(function() {
    jQuery(this).addClass('use-svg use-icon use-icon-arrow-button svg-margin svg-reverse');
});

function initBackToTop(){
    let button = jQuery('<span class="back-to-top use-svg ise-icon use-icon-back-to-top"></span>').appendTo('footer');
    button.on('click',function(){
        jQuery("html, body").animate({ scrollTop: 0 }, "fast");
        return false;
    });
}


function sliderSlickFullWidth() {
    let slider = jQuery('.slick-list');

    slider.css({
        'width': '100vw',
        'position': 'relative',
        'left': '0',
        'right': '50%',
        'margin-left': '0',
        'margin-right': '-50vw'
    });
}

function initialiseMain() {
    jQuery('.js-menu-burger').click(function () {
        jQuery(this).toggleClass('is-active');
        jQuery('html').toggleClass('is-active-modal is-active-menu-burger');
    });

    /*
     * Pouvoir cliquer sur l'ensemble d'une carte
     */
    jQuery('.js-card').click(function () {
        window.location.href = jQuery(this).find('.js-button').attr('href');
    }).hover(function () {
        jQuery(this).find('.js-button').toggleClass('is-active');
    });

    jQuery('.card:not(.card-sequence)').hover(function () {
        jQuery(this).find('.button, .button-link, .js-button').toggleClass('is-hover');
    });

    // listes déroulantes :
    jQuery('.toggle-block').each(function () {
        let $element= jQuery(this);
        let $btn = jQuery(this).find('> .toggle-block__btn');

        $btn.click(function(){
            $element.toggleClass('is-active');
        });
    });

    jQuery('.nr-js-filters-mobile').click(function(){
        jQuery(this).parent().toggleClass('active');
    });
}

function initialiseFooter() {
    jQuery('.footer-menu-title.footer-menu-title__js-toggle').each(function () {
        let $element= jQuery(this);
        let $menu = jQuery(this).next('.menu');
        let $window = jQuery(window);

        $element.click(function(){
            if( $window.width() > 767 )
                return false;

            $menu.slideToggle();
            $element.toggleClass('js-active');
        });
    });
}

function inputChange() {
    jQuery('input[type="radio"]').change(function () {
        jQuery(this).parent().toggleClass('is-active');
    });
}

function patternBgColor(parent, block) {
    if (jQuery(parent).hasClass('bg-color-primary')) {
        block.addClass('block-pattern-bg-color-primary');
    } else if (jQuery(parent).hasClass('bg-color-secondary')) {
        block.addClass('block-pattern-bg-color-secondary');
    } else if (jQuery(parent).hasClass('bg-color-tertiary')) {
        block.addClass('block-pattern-bg-color-tertiary');
    } else if (jQuery(parent).hasClass('bg-color-white')) {
        block.addClass('block-pattern-bg-color-white');
    } else if (jQuery(parent).hasClass('bg-color-black')) {
        block.addClass('block-pattern-bg-color-black');
    } else if (jQuery(parent).hasClass('bg-color-neutral-1')) {
        block.addClass('block-pattern-bg-color-neutral-1');
    }
}

function stickToSides() {
    let $minWidth = 0;
    let $maxWidth = 2600;
    let $window = jQuery(window);

    jQuery('.stickToLeft').each(function () {
        let $element = jQuery(this);
        let $offset = $element.offset().left;
        let $left = $offset;
        let $newWidth = $width + $left;
        let $css = '';

        if ($window.width() > $minWidth && $window.width() < $maxWidth) {
            $css = 'margin-left:-'+ $offset +'px;width:'+ $newWidth +'px';

            if($element.is('.stickToLeftKeepContentWidth')){
                $css = $css + ';padding-left:'+$offset+'px !important';
            }

            $element.css('cssText', $css);

        }
        else {
            $element.css('margin-left', '');
            $element.css('padding-left', '');
        }
    });

    jQuery('.stickToRight').each(function () {
        let $element = jQuery(this);
        let $offset = $element.offset().left;
        let $width = $element.outerWidth();
        let $right = $window.width() - ($offset + $width);
        let $newWidth = $width + $right;
        let $css = '';

        if ($window.width() > $minWidth && $window.width() < $maxWidth) {
            $css = 'margin-right:-'+ $right +'px;width:'+ $newWidth +'px';

            if($element.is('.stickToRightKeepContentWidth')){
                $css = $css + ';padding-right:'+$right+'px !important';
            }

            $element.css('cssText', $css);
        }
        else{
            $element.css('cssText', '');
        }
    });
}

function initialiseDelayTransitions(){
    jQuery('.js-delay-transition').each(function(){
        let $element = jQuery(this);
        $element.addClass('js-delay-transition-init');
    });
}
function delayTransitions(){
    jQuery('.js-delay-transition-init').percentWithinViewport();
    jQuery('.js-delay-transition-init').each(function(){
        let $element = jQuery(this);
        let $percent = $element.attr('data-percent-viewport');
        if($percent > 40){
            $element.addClass('js-delay-transition-active');
        }
        else if( $element.is('.js-delay-transition-can-reverse') ){
            $element.removeClass('js-delay-transition-active');
        }
    });
}

function initialiseParallax(){
    jQuery('.parallax').each(function(){
        let $element = jQuery(this);
        $element.parent().css('overflow','hidden');
    });
}
function runParallax(){
    let minWidth = 768;
    let $window = jQuery(window);

    if ($window.width() < minWidth) {
        jQuery('.parallax').css({
            '-webkit-transform': '',
            '-moz-transform': '',
            '-ms-transform': '',
            '-o-transform': '',
            'transform': ''
        });
        return;
    }

    jQuery('.parallax').percentWithinViewport();
    jQuery('.parallax').each(function(){
        let $element = jQuery(this);
        let translateX = '';
        let translateY = '';
        let $percent = $element.attr('data-percent-viewport');

        if($element.is('.parallax-x')){
            let $slowX = 9;
            let $reverseX = '';
            let $translateXVal = '';

            if($element.is('.parallax-x-slow-1')) $slowX = 3;
            else if($element.is('.parallax-x-slow-2')) $slowX = 6;
            else if($element.is('.parallax-x-slow-3')) $slowX = 9;
            else if($element.is('.parallax-x-slow-4')) $slowX = 12;
            else if($element.is('.parallax-x-slow-5')) $slowX = 15;

            if($element.is('.parallax-x-reverse')) $reverseX = '-';

            if( ($element.offset().top) > jQuery(window).scrollTop() ) {
                $translateXVal = (100 - $percent) / $slowX;
            }
            else{
                $translateXVal = 0;
            }

            translateX = 'translateX(' + $reverseX + $translateXVal + '%)';
        }

        if($element.is('.parallax-y')){
            let $slowY = 9;
            let $reverseY = '';
            let $translateYVal = '';

            if($element.is('.parallax-y-slow-1')) $slowY = 3;
            else if($element.is('.parallax-y-slow-2')) $slowY = 6;
            else if($element.is('.parallax-y-slow-3')) $slowY = 9;
            else if($element.is('.parallax-y-slow-4')) $slowY = 12;
            else if($element.is('.parallax-y-slow-5')) $slowY = 15;

            if($element.is('.parallax-y-reverse')) $reverseY = '-';

            if( ($element.offset().top) > jQuery(window).scrollTop() ) {
                $translateYVal = (100 - $percent) / $slowY;
            }
            else{
                $translateYVal = 0;
            }

            translateY = 'translateY(' + $reverseY + $translateYVal + '%)';
        }

        if( translateX || translateY ){
            $element.css({
                '-webkit-transform': translateX +' '+translateY ,
                '-moz-transform': translateX +' '+translateY,
                '-ms-transform': translateX +' '+translateY,
                '-o-transform': translateX +' '+translateY,
                'transform': translateX +' '+translateY
            });
        }
    });
}

function stickyTop(){
    let $stickyContainer = jQuery('.sticky-header');
    let $window = jQuery(window);
    let $body = jQuery('body');
    let $docViewTop = $window.scrollTop();
    let $padding = 0;


    /*if( $window.width() < 992 ){
        $body.css('padding-top','');
        $body.removeClass('js-sticky-on');
        $stickyContainer.removeClass('sticky--scrolled');
        return false;
    }*/

    if( $stickyContainer.length > 0 )
    {
        if( !$body.is('.js-sticky-active'))
        {
            if(isInvisibleInWindow($stickyContainer, 100)){
                $stickyContainer.addClass('sticky--scrolled');
                $body.addClass('js-sticky-active');
                $stickyContainer.attr('data-initial-height', $stickyContainer.outerHeight() );

                if( $body.is('.header__color-dark') ){
                    $body.addClass('js-header-switch-color');
                }
            }
        }

        if( $docViewTop > 0 && $body.is('.js-sticky-active') ){
            $body.addClass('js-sticky-on');

            //if( !$body.is('.header_template_template1') ){
            $padding = $stickyContainer.attr('data-initial-height');
            $body.css('padding-top',$padding);
            //}

            if( $body.is('.js-header-switch-color') ){
                $body.removeClass('header__color-dark');
            }
        }
        else if( $body.is('.js-sticky-active') ){
            $body.removeClass('js-sticky-on');

            if( $body.is('.js-header-switch-color') ){
                $body.addClass('header__color-dark');
            }

            //if( !$body.is('.header_template_template1') ){
            $padding = $stickyContainer.attr('data-initial-height');
            $body.css('padding-top',$padding + 'px');
            //}
        }
    }
}

function isInvisibleInWindow(elem,offset=0){
    let $elem = jQuery(elem);
    let $window = jQuery(window);

    let docViewTop = $window.scrollTop();
    let docViewBottom = docViewTop + $window.height();

    let elemTop = $elem.offset().top;
    let elemBottom = elemTop + $elem.height();

    return ((elemTop >= docViewBottom) || ((elemBottom + offset) <= docViewTop));
}

function initBookForm(){
    let $form = jQuery('#form_reservation');
    let $arrivalDate = $form.find('#field_arrivaldate');

    $arrivalDate.attr('name', 'arrivalDate');
    $arrivalDate.val('2024-01-01');
    $form
        .attr('target','_blank')
        .attr('action','https://www.secure-hotel-booking.com/smart/Almoria/2937/fr-FR/?guestCountSelector=ReadOnly');

    $form.on('submit',function(){
        //return false;
    })
}