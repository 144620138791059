jQuery(document).ready(function () {

});

jQuery(window).load(function () {
    menu_page();
});


jQuery(window).resize(function () {

});

jQuery(window).scroll(function () {
    menu_page();
});


function menu_page(){
    let $entries = jQuery('.component-page_menu__menu a');
    $entries.each(function(){
        let $element = jQuery(this);
        let href= $element.attr('href');

        if(/^#/.test(href)) {
            if( jQuery(href).is('.vc_active') || jQuery(href).is('.active') ){
                $element.addClass('is-active');
            }
            else{
                $element.removeClass('is-active');
            }
        }
    });
}