/**
 * Gestion de fichier sprite SVG
 * Et implémentation de SVG via classes CSS
 */

// Import des svg dans le lepreuchaun (sprite)
requireAll(require.context('../icon/', true, /\.svg$/));

jQuery(document).ready(function () {
    //let $toggleBlocks = jQuery('.toggle-block');
    //$toggleBlocks.find('> .toggle-block__btn').addClass('use-svg use-icon use-icon-chevron-down on-right flex-row-reverse');

    setSvg();

    jQuery('.slick').on('init', function () {
        setSvg();
    });
});

jQuery(document).ajaxComplete(function () {
    setSvg();
});

jQuery(document).on('frmPageChanged', function (event, form, response) {
    setSvg();
});

// Autoloader JS
function requireAll(r) {
    r.keys().forEach(r);
}

// Ajout de SVG par détection de classes CSS : use-icon use-icon-[NAME]
function setSvg() {
    let use_svg = jQuery('.use-svg:not(.is-loaded)');
    if (use_svg.length) {
        use_svg.each(function () {
            let elt = jQuery(this);
            let classList = jQuery(this).attr('class').split(/\s+/);
            jQuery.each(classList, function (index, item) {
                if (item.includes('use-icon-')) {
                    jQuery(elt).addClass('is-loaded');
                    let svg = item.replace('use-', '');
                    elt.prepend('<svg class="sprite-icon sprite-' + svg + '"><use xlink:href="#' + svg + '"></use></svg>');
                }

                if (item.includes('use-pattern-')) {
                    jQuery(elt).addClass('is-loaded');
                    let svg = item.replace('use-', '');
                    elt.prepend('<svg class="sprite-pattern sprite-' + svg + '"><use xlink:href="#' + svg + '"></use></svg>');
                }
            });
        });
    }
}
